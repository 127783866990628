.banner {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    &>span {
        font-size: 0.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.2rem;
        position: absolute;
        bottom: 0;
        left: 0.6rem;
    }

    &>img {
        width: 100%;
    }
}

@media screen and (min-width: 700px) {
    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;

        &>span {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            position: absolute;
            bottom: 0;
            left: 12px;
        }

        &>img {
            width: 100%;
        }
    }
}