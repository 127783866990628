.bottom {
    margin-bottom: 3.45rem;
}



@media screen and (min-width: 700px) {
    .bottom {
        margin-bottom: 49px;
    }
}