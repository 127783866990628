.text {
    width: 7.5rem;
    height: 1.8rem;
    background: rgba($color:#000000, $alpha:0.3);
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;

    &>span {
        font-size: 0.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.2rem;
    }
}


@media screen and (min-width:750px) {
    .text {
        width: 150px;
        height: 36px;
        background: rgba($color: #000000, $alpha: 0.3);
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        &>span {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
        }
    }
}