.banner {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    &>img {
        width: 100%;
    }

    &>h1 {
        font-size: 1.4rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 2rem;
        position: absolute;
        top: 3rem;
        left: 0.6rem;
    }

    &>h2 {
        font-size: 0.9rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 1.2rem;
        position: absolute;
        top: 5.6rem;
        left: 0.6rem;
    }
}

@media screen and (min-width:750px) {
    .banner {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: -70px;

        &>img {
            width: 100%;

        }

        &>h1 {
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 40px;
            position: absolute;
            top: 100px;
            left: 12px;
        }

        &>h2 {
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 24px;
            position: absolute;
            top: 170px;
            left: 12px;
        }
    }
}