.main {
    width: 100%;
    padding: 0 0.6rem;
    padding-top: 0.6rem;
    padding-bottom: 1.5rem;
    position: relative;

    &>p {
        font-size: 0.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.2rem;
        margin-bottom: 0.9rem;
    }

    &>ul {
        width: 100%;
        display: flex;
        flex-direction: column;

        .list_item {
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 0.6rem;

            .tag {
                width: 2.2rem;
                height: 1rem;
                background: url('../../../assets/images/矩形@3x.png');
                background-repeat: no-repeat;
                background-size: contain;
                display: flex;
                align-items: center;
                justify-content: center;

                &>span {
                    font-size: 0.7rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                }
            }

            .list_item_text {
                font-size: 0.75rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 1.2rem;
                margin-left: 0.3rem;

                &>span {
                    font-size: 0.75rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #8884A5;
                    line-height: 1.2rem;
                    margin-left: 0.2rem;
                }
            }
        }
    }

    .mode {
        width: 100%;
        display: flex;
        flex-direction: column;

        .mode_title {
            width: 3.6rem;
            height: 1.2rem;
            background: linear-gradient(106deg, #FF81B6 0%, #4D00A9 100%);
            border-radius: 0.2rem 0.2rem 0rem 0rem;
            margin-top: 0.7rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &>span {
                font-size: 0.7rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 1.2rem;
            }
        }

        .mode_content {
            width: 100%;
            border-radius: 0rem 0.2rem 0.2rem 0.2rem;
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(255, 121, 178, 1), rgba(117, 0, 255, 1)) 1 1;
            padding: 1rem 0.55rem;

            .mode_item {
                display: flex;
                justify-content: space-between;

                &:last-of-type {
                    margin-top: 0.75rem;
                }

                .mode_text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &>h1 {
                        font-size: 0.75rem;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #AA00F7;
                        line-height: 1.2rem;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 0.5rem;
                    }

                    &>h2 {
                        display: flex;
                        align-items: center;

                        &>span {
                            font-size: 0.75rem;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                            line-height: 1.2rem;
                        }

                        &>img {
                            width: 0.6rem;
                            height: 0.6rem;
                            margin-left: 0.5rem;
                        }
                    }

                    &>span {
                        font-size: 0.6rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 1.2rem;
                    }
                }

                .pic {
                    width: 5rem;
                    height: 5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: url('../../../assets/images/矩形备份@3x.png');
                    background-repeat: no-repeat;
                    background-size: contain;

                    .mailbox {}
                }
            }
        }
    }

    .text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &>p {
            font-size: 0.75rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 1.2rem;
            margin-top: 1.5rem;
        }

        &>span:first-of-type {
            margin-top: 1.5rem;
            margin-bottom: 0.2rem;
        }

        &>span {
            font-size: 0.75rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 1.2rem;
        }
    }

    .btn_box {
        width: 100%;
        height: 3.45rem;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        z-index: 99;
        background: #FFFFFF;
        box-shadow: inset 0rem 0.05rem 0rem 0rem rgba(0, 0, 0, 0.07);
        opacity: 0.96;
        backdrop-filter: blur(0.5rem);
        display: flex;
        padding-top: 0.25rem;
        justify-content: center;

        .btn {
            width: 17.55rem;
            height: 2rem;
            background: url('../../../assets/images/矩形2@3x.png');
            background-repeat: no-repeat;
            background-size: 17.55rem 2rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &>span {
                font-size: 0.75rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 1.05rem;
            }

            &>img {
                width: 0.8rem;
                height: 0.8rem;
                margin-left: 0.3rem;
            }
        }
    }
}

.btm {
    width: 100%;
    height: 5.05rem;
}

@media screen and (min-width: 700px) {
    .btm {
        width: 100%;
        height: 101px;
    }

    .main {
        width: 700px;
        // background: #0F0949;
        padding: 0 12px;
        padding-top: 12px;
        padding-bottom: 30px;
        position: relative;

        &>p {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            margin-bottom: 18px;
        }

        &>ul {
            width: 100%;
            display: flex;
            flex-direction: column;

            .list_item {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 12px;

                .tag {
                    width: 44px;
                    height: 20px;
                    background: url('../../../assets/images/矩形@3x.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>span {
                        font-size: 14px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                    }
                }

                .list_item_text {
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                    margin-left: 6px;

                    &>span {
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #8884A5;
                        line-height: 24px;
                        margin-left: 4px;
                    }
                }
            }
        }

        .mode {
            width: 100%;
            display: flex;
            flex-direction: column;

            .mode_title {
                width: 72px;
                height: 24px;
                background: linear-gradient(106deg, #FF81B6 0%, #4D00A9 100%);
                border-radius: 4px 4px 0px 0px;
                margin-top: 14px;
                display: flex;
                align-items: center;
                justify-content: center;

                &>span {
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 24px;
                }
            }

            .mode_content {
                width: 100%;
                border-radius: 0px 4px 4px 4px;
                border: 1px solid;
                border-image: linear-gradient(180deg, rgba(255, 121, 178, 1), rgba(117, 0, 255, 1)) 1 1;
                padding: 20px 11px;

                .mode_item {
                    display: flex;
                    justify-content: space-between;

                    &:last-of-type {
                        margin-top: 15px;
                    }

                    .mode_text {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        &>h1 {
                            font-size: 15px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #AA00F7;
                            line-height: 24px;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 10px;
                        }

                        &>h2 {
                            display: flex;
                            align-items: center;

                            &>span {
                                font-size: 15px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #FFFFFF;
                                line-height: 24px;
                            }

                            &>img {
                                width: 12px;
                                height: 12px;
                                margin-left: 10px;
                            }
                        }

                        &>span {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 24px;
                        }
                    }

                    .pic {
                        width: 100px;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url('../../../assets/images/矩形备份@3x.png');
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }


            }
        }

        .text {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &>p {
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 24px;
                margin-top: 30px;
            }

            &>span:first-of-type {
                margin-top: 30px;
                margin-bottom: 4px;
            }

            &>span {
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 24px;
            }
        }

        .btn_box {
            width: 700px;
            height: 49px;
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            z-index: 99;
            background: #FFFFFF;
            box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.07);
            opacity: 0.96;
            backdrop-filter: blur(10px);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            .btn {
                width: 351px;
                height: 40px;
                background: url('../../../assets/images/矩形2@3x.png');
                background-repeat: no-repeat;
                background-size: 351px 40px;
                display: flex;
                align-items: center;
                justify-content: center;



                &>span {
                    font-size: 15px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 21px;
                }

                &>img {
                    width: 16px;
                    height: 16px;
                    margin-left: 6px;
                }
            }
        }
    }

}