.main {
    width: 100%;
    height: auto;
    padding: 0.8rem 0.6rem 0.5rem 0.6rem;
    // background: #0F0949;
    position: relative;

    .road {
        width: 0.05rem;
        height: 45rem;
        position: absolute;
        top: 1.6rem;
        left: 1.3rem;
        z-index: 0;
    }

    .list_item {
        position: relative;
        z-index: 1;

        &:first-of-type .item_title {
            margin-top: 0;
        }

        .item_title {
            display: flex;
            align-items: center;
            margin-top: 1.2rem;

            .title_pic {
                width: 1.4rem;
                height: 1.4rem;
                background: url('../../../assets/images/椭圆形@3x.png');
                background-repeat: no-repeat;
                background-size: contain;
                display: flex;
                align-items: center;
                justify-content: center;

                &>span {
                    font-size: 0.75rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 1.05rem;
                }
            }

            &>h1 {
                font-size: 0.8rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #7E77FF;
                line-height: 1.4rem;
                margin-left: 0.7rem;
            }
        }

        .time {
            display: block;
            font-size: 0.75rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8783A4;
            line-height: 1.2rem;
            margin-left: 2.1rem;
            margin-bottom: 0.5rem;
        }

        .p {
            font-size: 0.75rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 1.2rem;
            padding-left: 2.1rem;
        }

        .emp {
            font-size: 0.75rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8783A4;
            line-height: 1.2rem;
            padding-left: 2.1rem;
            margin-bottom: 0.5rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .target {
        display: flex;
        flex-direction: column;

        .title {
            width: 3.6rem;
            height: 1.2rem;
            background: linear-gradient(106deg, #FF81B6 0%, #4D00A9 100%);
            border-radius: 0.2rem 0.2rem 0rem 0rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1.8rem;

            &>span {
                font-size: 0.7rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 0.85rem;
            }
        }

        .content {
            width: 100%;
            border-radius: 0rem 0.2rem 0.2rem 0.2rem;
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(255, 121, 178, 1), rgba(117, 0, 255, 1)) 1 1;
            position: relative;
            background: #0F0949;
            padding: 1rem 0.8rem;
            z-index: 1;
            display: flex;
            flex-direction: column;

            .item {
                font-size: 0.75rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 1.2rem;
            }

            .detail {
                font-size: 0.75rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 1.2rem;
                margin-top: 0.9rem;
            }
        }
    }

    .btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;

        &>img {
            width: 0.8rem;
            height: 0.8rem;
            margin-left: 0.3rem;
        }

        &>span {
            font-size: 0.75rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 1.2rem;
        }
    }

    .back {
        width: 2.2rem;
        height: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 0rem 0rem 0.2rem 0rem rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        position: fixed;
        right: 0.6rem;
        bottom: 2rem;
        z-index: 99;

        &>img {
            width: 0.5rem;
            height: 0.85rem;
        }
    }
}

.btm2 {
    width: 100%;
    height: 5.05rem;
}

@media screen and (min-width:750px) {
    .btm2 {
        width: 100%;
        height: 101px;
    }

    .main {
        width: 100%;
        height: auto;
        padding: 16px 12px 10px 12px;

        // background: #0F0949;
        position: relative;



        .road {
            width: 1px;
            height: 900px;
            position: absolute;
            top: 32px;
            left: 26px;
            z-index: 0;
        }


        .list_item {
            position: relative;
            z-index: 1;

            &:first-of-type .item_title {
                margin-top: 0;
            }

            .item_title {
                display: flex;
                align-items: center;
                margin-top: 24px;

                .title_pic {
                    width: 28px;
                    height: 28px;
                    background: url('../../../assets/images/椭圆形@3x.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>span {
                        font-size: 15px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 21px;
                    }
                }

                &>h1 {
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #7E77FF;
                    line-height: 28px;
                    margin-left: 14px;
                }
            }

            .time {
                display: block;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8783A4;
                line-height: 24px;
                margin-left: 42px;
                margin-bottom: 10px;
            }

            .p {
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 24px;
                padding-left: 42px;

            }

            .emp {
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8783A4;
                line-height: 24px;
                padding-left: 42px;
                margin-bottom: 10px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .target {
            display: flex;
            flex-direction: column;

            .title {
                width: 72px;
                height: 24px;
                background: linear-gradient(106deg, #FF81B6 0%, #4D00A9 100%);
                border-radius: 4px 4px 0px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 36px;

                &>span {
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 17px;
                }
            }

            .content {
                width: 100%;
                border-radius: 0px 4px 4px 4px;
                border: 1px solid;
                border-image: linear-gradient(180deg, rgba(255, 121, 178, 1), rgba(117, 0, 255, 1)) 1 1;
                position: relative;
                background: #0F0949;
                padding: 20px 16px;
                z-index: 1;
                display: flex;
                flex-direction: column;

                .item {
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                }

                .detail {
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                    margin-top: 18px;
                }
            }
        }

        .btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;

            &>img {
                width: 16px;
                height: 16px;
                margin-left: 6px;
            }

            &>span {
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 24px;
            }
        }

        .back {

            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            position: fixed;
            right: 12px;
            bottom: 40px;
            z-index: 99;
            display: none;

            &>img {
                width: 10px;
                height: 17px;
            }
        }
    }
}