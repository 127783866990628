* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

.App {
  width: 100vw;
  font-size: 0;
  background: #0F0949;
}

@media screen and (min-width:700px) {
  .App {
    width: 700px;
    margin: 0 auto;
    background: #0F0949;
  }

  // body {
  //   width: 700px;
  //   background: #0F0949;

  //   margin: 0 auto;
  // }
}