

.footer {
    width: 100%;
    height: 3rem;
    background: #FFFFFF;
    box-shadow: 0rem -0.05rem 0rem 0rem rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(0rem);
    display: flex;
    align-items: center;
    justify-content: center;

    &>span {
        font-size: 0.45rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #bdbdbd;
        line-height: 0.8rem;
    }
}


@media screen and (min-width: 700px) {
  

    .footer {
        width: 100%;
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.04);
        backdrop-filter: blur(0px);
        display: flex;
        align-items: center;
        justify-content: center;


        &>span {
            font-size: 9px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #bdbdbd;
            line-height: 16px;
        }
    }
}